.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  padding: 15px;
  font-size: 32px;
  font-weight: $g-bold;
  text-align: center;
  background-image: url("../images/track.png");
  background-position: top center;
  background-size: auto 180px;

  @media ($smDown) {
    font-size: 24px;
  }

  @media (max-height: 599px) {
    background-size: auto 90px;
  }
}
