.root {
  flex-shrink: 0;
  color: $white;
}

.upper {
  padding: 58px 0;
  background-color: $grey10;

  @media ($smDown) {
    padding: 15px 0;
  }
}

.lower {
  padding: 29px 0;
  font-size: 13px;
  background-color: $grey15;
}

.lowerInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.copyright {
  padding-left: 0.5em;
  font-size: 16px;
}

.grid {
  $spacing: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: calc($spacing * -1);

  > * {
    flex-grow: 1;
    padding: $spacing;

    @media ($smDown) {
      width: 50%;
    }

    @media ($xsDown) {
      width: 100%;
      padding: 0;
      border-bottom: 1px solid $grey30;
    }
  }
}

.mobileOnly {
  display: block;
  width: 16px;

  @media ($xsUp) {
    display: none;
  }
}

.heading {
  padding-left: 15px;
  margin-bottom: 8px;
  font-weight: $g-bold;

  @media ($xsDown) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    margin-bottom: 0;
    font-size: 16px;
  }

  @media ($xsUp) {
    pointer-events: none;

    svg {
      display: none;
    }
  }
}

.list {
  padding: 15px;
  font-size: 16px;
  line-height: 1.1;

  > * + * {
    margin-top: 12px;
  }
}

.link {
  color: $white;

  &:hover {
    color: $white;
    text-decoration: underline;
  }
}

.iconLink {
  font-size: 20px;
}

.socialMedia {
  display: flex;
  align-items: center;
  font-size: 18px;

  > * {
    padding: 3px;
  }
}

.logo {
  height: 61px;
}

.flipped {
  transform: rotate(180deg);
}
